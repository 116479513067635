// import "./App.css";
import React, { useState } from "react";
// import { Mixpanel } from './Mixpanel.js';

import { Route, Routes, BrowserRouter } from "react-router-dom";

// import videoFile from "./stars-fly.mp4";
// import videoTransition from "./q7.mp4";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Landing />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

// I might make it consistent behavior that will be sexy:
// 0. Have default black in index.html in case 1 takes longer for some reason to load
// 1. Load black background with NN that fades in to tease
// 2. Possibly do a transition from NN into the page (for example NN increases in size, and the
// background slowly fades-in). Not necessary for now tho, but migth be a cool flow
// 3. Only if a) 2 seconds of full animation is done; b) the videos I need for future transitions are
// done - show next page
// 4. Show the video BG + video me + Nazar Novak + Choose success + Enter >
// 5. Click on Enter >
// 6. Transition out step 4 into a new page where it will be less movement, and you can choose
// some kind of a section you are interested in knowing more

const Landing = () => {
  const [introFinished, setIntroFinished] = useState(false);
  const [bgVideoLoaded] = useState(false);
  const [nazarVideoLoaded] = useState(false);
  const [nazarVideo2Loaded] = useState(false);

  // This is to show the second Nazar video properly before transitioning out
  const [firstVideoFinished, setFirstVideoFinished] = useState(false);

  const [enterSiteClicked, setEnterSiteClicked] = useState(false);

  //   useEffect(() => {
  //     let vid = document.getElementById("stars-video");
  //     if (vid) {
  //       vid.playbackRate = 1.25;
  //       let autoPlay = vid.play();

  //       if (autoPlay !== undefined) {
  //         autoPlay
  //           .then((_) => {
  //             // Autoplay started!
  //           })
  //           .catch((error) => {
  //             // Autoplay not allowed
  //             // Gives this text: "NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
  //             if (error.name === "NotAllowedError") {
  //               // Somehow tell the user that the best experience is with video being autoplayed
  //               // Or show a gif instead
  //               // Or an HQ photo instead
  //               alert(123);
  //             }
  //           });
  //       }
  //     }
  //   }, []);

  const onEnter = (e) => {
    e.preventDefault();
    setEnterSiteClicked(true);
  };

  //   <video id="transparency" className="video" autoPlay loop muted>
  //     <source
  //       src="https://rotato.netlify.app/alpha-demo/movie-webm.webm"
  //       type="video/webm"
  //     />
  //   </video>;

  // <video id="transition-video" className="video" autoPlay loop muted>
  //      <source src={videoTransition} type="video/mp4" />
  //    </video>

  // onLoadEnd attribute on video to set state to "loaded"? Otherwise, show a cool logo like Ferrari does
  // for example with white logo, that goes into dark and reveals the site

  // This will be the animation for the intro signature/initials
  setTimeout(() => {
    setIntroFinished(true);
  }, 3000);

  if (enterSiteClicked) {
    setTimeout(() => {
      setFirstVideoFinished(true);
    }, 3000);
  }

  // TODO: Just show NN for now and deploy it :D
  let firstVideoReady =
    false &&
    introFinished &&
    bgVideoLoaded &&
    nazarVideoLoaded &&
    nazarVideo2Loaded;

  // Hacky way to show progress. It's not perfect, because the video might be stuck more than audios,
  // but this is still good feedback to the user that something is loading. Better than being confused
  let contentLoadPercent = Math.ceil(
    (introFinished + bgVideoLoaded + nazarVideoLoaded + nazarVideo2Loaded) * 20
  );

  return (
    <>
      <div
        id="initials-screen"
        className={"" + (firstVideoReady ? "fade-out" : "")}
      >
        {/* <video
          id="stars-video"
          className="video"
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setBGVideoLoaded(true)}
        >
          <source src={videoFile} type="video/mp4" />
        </video> */}
        <div id="centered">
          <h1 id="initials">NN</h1>
          <progress id="progress" max="100" value={contentLoadPercent}>
            {contentLoadPercent}%
          </progress>
        </div>
      </div>

      <div
        id="first-video"
        className={
          "" +
          (firstVideoReady && !firstVideoFinished ? "fade-in" : "") +
          " " +
          (enterSiteClicked && firstVideoFinished ? "fade-out" : "")
        }
      >
        {/* <video
          id="main-video"
          className="video"
          autoPlay
          loop
          muted
          onLoadedData={() => setBGVideoLoaded(true)}
        >
          <source src={videoFile} type="video/mp4" />
        </video> */}
        <div id="main-content">
          <aside>
            {/* <img
              id="zu"
              src={zu}
              alt="Portrait"
              style={{ display: enterSiteClicked ? "none" : "block" }}
              onLoad={() => {
                setNazarVideoLoaded(true);
              }}
            />
            <img
              id="zu"
              src={zu2}
              style={{ display: enterSiteClicked ? "block" : "none" }}
              alt="Portrait"
              onLoad={() => {
                setNazarVideo2Loaded(true);
              }}
            /> */}
          </aside>
          <main>
            <div id="text-container">
              <h1>Nazar Novak</h1>
              <h2>Choose success</h2>
              <br />
              <br />
              <br />
              <br />
              <h2>
                <a href="/" onClick={onEnter}>
                  Enter &gt;
                </a>
              </h2>
            </div>
          </main>
        </div>
      </div>

      <div
        id="menu-screen"
        style={{ display: enterSiteClicked ? "block" : "none" }}
        className={
          "" + (enterSiteClicked && firstVideoFinished ? "fade-in" : "")
        }
      >
        <nav>
          <a href="/">One</a>
          <a href="/">Two</a>
          <a href="/">Three</a>
          <a href="/">Four</a>
        </nav>
      </div>
    </>
  );
};

export default App;
